import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"

class BlogPostTemplate extends React.Component {
  
  componentDidMount() {
    const sessionKey  = window.sessionStorage.getItem('rapplerPlus');
    const email = window.sessionStorage.getItem("rapplerPlusEmail");
    const password = window.sessionStorage.getItem("rapplerPlusPassword");

    if(sessionKey)
      return;
    
    if(email)
      return;

    if(password === "r4ppl3rPlu$El3ction")
      return;

    window.location.href = "/";
  }
  
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        {/* { post.frontmatter.graphicTitle ? <img src={`${post.formatString.graphicTitle}`} /> :<h1 style={{fontFamily: "roboto"}}>{post.frontmatter.title}</h1>} */}
        <img src={`${post.frontmatter.graphicTitle}`} /> 
        <p
          style={{
            color: `#959595`,
            ...scale(-1 / 5),
            display: `block`,
            marginBottom: rhythm(1),
            marginTop: rhythm(-1),
            fontFamily: "roboto"
          }}
        >
          {post.frontmatter.date}
        </p>
        <p style={{color: `#959595`,
            ...scale(-1 / 5),
            display: `block`,
            marginBottom: rhythm(1),
            marginTop: rhythm(-1),
            fontFamily: "roboto"}}>
            by {post.frontmatter.author}
          </p>
        <div style={{color: `#959595`}} dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <Bio />

        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} style={{color: '#e76229'}} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} style={{color: '#e76229'}} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        graphicTitle
        author
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
