/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author, social } = data.site.siteMetadata
        return (
          <div
            style={{
              display: `flex`,
              marginBottom: rhythm(2.5),
            }}
          >
            {/* <Image
              fixed={data.avatar.childImageSharp.fixed}
              alt={author}
              style={{
                marginRight: rhythm(1 / 2),
                marginBottom: 0,
                minWidth: 50,
                borderRadius: `100%`,
              }}
              imgStyle={{
                borderRadius: `50%`,
              }}
            /> */}
           <div>
             {/* Be Updated With Rappler PLUS. */}
              {/* <div style={{height: "60px", width: "145px", background: "#475764", color: "#fff", borderRadius: "15px", position: "relative"}} onClick={() => { window.open("https://www.facebook.com/groups/rapplerplus/")}}>
                <i className="fa fa-user-plus" style={{fontSize: '24px', position: "absolute", top: "17px", left: "15px"}}></i>
                <p style={{position: "absolute", top:"8px", left:"47px", fontSize: "12px"}}>Join us on our <br /> Facebook Group</p>
              </div> */}
           </div>
          </div>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`

export default Bio
